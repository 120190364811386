@media (min-width: 768px) {
  .css-13cwrno-MuiGrid-root {
    flex-basis: 47.333333% !important;
    max-width: 47.333333% !important;
  }
}

@media (min-width: 768px) {
  .css-15cshe0-MuiGrid-root {
    flex-basis: 47.333333% !important;
    max-width: 47.333333% !important;
  }
}
