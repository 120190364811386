.body_jobs{
    background-color: #F0F2F5 !important;
    border-radius: 20px;
}
.jobs_details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
}

.jobs_details_title{
    font-size: large;
    font-weight: 500;
    color: #1A73E8;    
}

.btn_apply_jobs{
    width: 200px !important;
}

.jobs_applly{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.jobs_applly_col{
    width: 200px !important;
    margin-left: 20px !important;
    margin-top: 20px !important;
}

.width_form_bac{
    min-width: 250px !important;
}

.txtarea_{
    border: 1px solid #D8DCDF;
    border-radius: 10px;
    padding: 6px;
    font-weight: 500;
    font-size: large;
    min-width: 281px;
}

textarea:focus-visible {    
    outline: #1A73E8 auto 1px !important;    
}


/* Img */
.btn_dowlaod{
    font-size: medium;
    font-weight: 600;
    border-radius: 20px;
    background-color: #fff;
    border: 2px solid #1A73E8;
    height: 30px;
    width: 291px;
    color: #16a4c7;
    text-align: center;
}

.dowload_png{
    color: #1A73E8;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row;
}

.dowload_png_txt{
    margin-bottom: 6px;
    margin-right: 12px;
    color: #1A73E8;
}

.label_inputs{
    display: flex !important;
    margin-bottom: 5px !important;
    text-align: left !important;
    width: 281px !important;
    font-size: medium;
    font-weight: 400;
}

.add_education_inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
}


@media screen and (min-width:700px) {
    .width_form_bac{
        min-width: 600px !important;;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}