.reviews_grid:hover {
  background-image: linear-gradient(to top, #3c96ef, #fff);
  border-radius: 20px;
}

.reviews_grid:hover .css-1q5gm4b-MuiTypography-root {
  color: #fff !important;
}

.reviews_grid:hover
  .MuiTypography-root.MuiTypography-h4.css-1scrlea-MuiTypography-root {
  color: #fff !important;
}

.css-91mnsa {
  max-width: 6rem !important;
}

.back_img_news {
  background-image: url("../../img/back.svg");
  background-color: #b1b1b1;
  /* min-height: 120px; */
  border-radius: 10px;
  /* filter: blur(0.5px); */
  padding-bottom: 20px;
}

.MuiOutlinedInput-input.MuiInputBase-input.css-5mmmz-MuiInputBase-input-MuiOutlinedInput-input {
  background: #fff !important;
  border-radius: 10px !important;
}

/*Css For Contact*/
.error_contact {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
}
.error_contact span {
  font-size: medium;
  font-weight: 100;
}

/*Css For NewsLetter*/

.error_newletter {
  width: 100%;
  margin-left: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  transition: all 0.5s ease-in-out;
}
.error_newletter span {
  font-size: large;
  font-weight: 100;
}

/*Css For Cover*/
@media (max-width: 450px) {
  .css-jxm6wf-MuiTypography-root {
    font-size: 1.5rem !important;
  }
  .css-1myi7kn-MuiTypography-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

/*Css For Contact*/

@media screen and (max-width: 444px) {
  .MuiBox-root.css-rqp54r > div > div {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.MuiBox-root.css-40yl7s {
  display: none !important;
}

/* Update Background of rotatio-Card */

/* .MuiBox-root.css-1iv2ohz > div > div {
  background-image: linear-gradient(
    to top,
    rgb(21 106 180),
    rgb(73 163 241 / 16%)
  ) !important;
} */

.test_bg {
  background-image: linear-gradient(
    to top,
    rgb(21 106 180),
    rgb(73 163 241 / 16%)
  ) !important;
}
