.css-1h15ts-MuiPaper-root-MuiCard-root {
  margin-left: 20px !important;
  width: 300px !important;
  height: fit-content !important;
}

@media screen and (max-width: 444px) {
  .css-1h15ts-MuiPaper-root-MuiCard-root {
    margin-left: 0px !important;
    width: 100% !important;
    height: fit-content !important;
  }
}
